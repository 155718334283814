import { AddCircleOutline, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  MenuItem,
  Popper,
  Stack,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
  useTheme,
} from "@mui/material";
import { type VirtualElement } from "@popperjs/core";
import classNames from "classnames";
import React, { type ReactElement } from "react";
import { Button } from "~/components/ui/core/Button";
import { useI18n } from "~/lib/i18n/useI18n";
import { isArray, isNumber, isString, omit } from "~/lib/lodash";

type Option = {
  id: number | string | boolean;
  name: string;
  count?: number;
};

type Props<T extends Option> = {
  value: T[];
  onChange?: (value: T[]) => void;
  disabled?: boolean;
  className?: string;
  options: T[];
  placeholder?: string;
  multiple?: boolean;
  label: string | JSX.Element;
  renderOption?: (option: T) => JSX.Element;
  buttonIcon?: JSX.Element;
  withChips?: boolean;
  onDelete?: (value: T[]) => void;
  maxDisplayedChips?: number;
  disablePortal?: boolean;
  showSelectAll?: boolean;
  isLoading?: boolean;
  disableClear?: boolean;
};

export const OptionsFilter = <T extends Option>({
  value,
  onChange,
  disabled,
  className,
  options,
  placeholder,
  multiple = true,
  label,
  renderOption,
  buttonIcon,
  withChips = true,
  onDelete,
  maxDisplayedChips = 3,
  disablePortal = true,
  showSelectAll = false,
  isLoading = false,
  disableClear = false,
}: Props<T>): ReactElement => {
  const { t } = useI18n();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { palette } = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  const displayedButtonIcon = buttonIcon ?? <AddCircleOutline fontSize="small" />;
  const displayClear = !disableClear && value.length > 0;
  const displaySelectAll = showSelectAll && value.length < options.length;

  return (
    <React.Fragment>
      <Button
        className={className}
        onClick={handleClick}
        disabled={disabled}
        size="small"
        variant="contained"
        color="white"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          {(value.length === 0 || !withChips) && <div className="flex text-gray-600">{displayedButtonIcon}</div>}

          {isString(label) ? <Typography>{label}</Typography> : label}

          {withChips && (
            <>
              {!!value.length && <Divider orientation="vertical" flexItem />}

              {value.length < maxDisplayedChips ? (
                <Stack direction="row" gap={1} flexWrap="wrap">
                  {value.map((val) => (
                    <Chip
                      color="primary-alt"
                      size="small"
                      label={val.name}
                      key={`${val.id}`}
                      onDelete={onDelete ? () => onDelete(value.filter((v) => v.id !== val.id)) : undefined}
                    />
                  ))}
                </Stack>
              ) : (
                <Chip color="primary-alt" size="small" label={`${value.length} selected`} />
              )}
            </>
          )}
        </Stack>
      </Button>

      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[{ name: "offset", options: { offset: [0, 4] } }]}
        popperOptions={{ strategy: "fixed" }}
        disablePortal={disablePortal}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple={multiple}
              disablePortal
              value={multiple ? value : value[0] ?? null}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              className={classNames(className, "min-w-[300px]")}
              options={options}
              renderOption={(props, option, { selected }) => {
                return (
                  <MenuItem {...props} aria-selected={false} className="flex justify-between px-2">
                    <Stack direction="row" alignItems="center">
                      <Checkbox size="small" checked={selected} sx={{ padding: "0px" }} className="mr-2" />

                      {renderOption ? renderOption(option) : <Typography>{option.name}</Typography>}
                    </Stack>

                    {isNumber(option?.count) && (
                      <Chip className="ml-2 h-[20px] min-w-[32px]" size="small" label={option.count} />
                    )}
                  </MenuItem>
                );
              }}
              isOptionEqualToValue={(option, value) => value.id === option.id}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue === null) onChange?.([]);
                else if (isArray(newValue)) onChange?.(newValue);
                else onChange?.(newValue.id === value[0]?.id ? [] : [newValue]);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: isLoading ? (
                        <CircularProgress size={20} className="p-0.5" />
                      ) : (
                        <Search fontSize="small" className="text-gray-500" />
                      ),
                      endAdornment: null,
                    }}
                    placeholder={isLoading ? t("common.loading") : placeholder}
                    sx={{
                      "border": "none",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        borderBottom: "1px solid",
                        borderColor: palette.grey[300],
                        borderRadius: 0,
                      },
                    }}
                  />
                );
              }}
            />

            {(displaySelectAll || displayClear) && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={4}
                className="border-t border-gray-200"
              >
                {displaySelectAll && (
                  <Button variant="text" className="w-full" onClick={() => onChange?.(options)}>
                    {t("common.select-all")}
                  </Button>
                )}

                {displayClear && (
                  <Button variant="text" className="w-full" onClick={() => onChange?.([])}>
                    {t("common.clear")}
                  </Button>
                )}
              </Stack>
            )}
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
};

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  width: "auto !important",
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.background.white,
    padding: "4px 0",
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  boxShadow: "none",
  overflow: "hidden",
  borderRadius: 4,
  zIndex: 50,
  backgroundColor: theme.palette.background.white,
}));

type PopperComponentProps = {
  anchorEl?: HTMLElement | VirtualElement | (() => HTMLElement) | (() => VirtualElement) | null | undefined;
  disablePortal?: boolean;
  open: boolean;
};

function PopperComponent(props: PopperComponentProps) {
  return <StyledAutocompletePopper {...omit(props, ["disablePortal", "anchorEl", "open"])} />;
}
