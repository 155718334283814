import classNames from "classnames";
import { forwardRef, type InputHTMLAttributes } from "react";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  dense?: boolean;
  grayed?: boolean;
  borderless?: boolean;
  withPrefix?: string | JSX.Element;
  affix?: string | JSX.Element;
  action?: JSX.Element;
};

export const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    dense = true,
    grayed,
    withPrefix: prefix,
    affix,
    action,
    type = "text",
    borderless = false,
    ...rest
  } = props;
  const { disabled } = rest;

  return (
    <div
      className={classNames({
        "relative flex w-full items-stretch justify-between": true,
        "mx-px rounded ring-1 ring-gray-300 hover:ring-gray-900": !borderless,
        "my-[1px] focus-within:my-0 focus-within:border-2 focus-within:!border-primary-500 hover:border-gray-700":
          !disabled,
      })}
    >
      {prefix && (
        <div className="pointer-events-none ml-px flex items-center justify-center rounded-l bg-gray-50 px-3 text-sm text-gray-600">
          {prefix}
        </div>
      )}

      <input
        ref={ref}
        className={classNames(className, {
          "w-full appearance-none outline-none": true,
          "rounded": !borderless,
          "py-2 px-3": !dense,
          "py-1.5 px-2 text-sm": dense,
          "text-gray-400": grayed || disabled,
          "text-gray-900": !grayed && !disabled,
        })}
        type={type}
        {...rest}
        value={rest.value ?? ""}
      />

      {action && (
        <div
          className={classNames({
            "flex items-center justify-center whitespace-nowrap rounded-r": true,
            "px-3": !dense,
            "px-1": dense,
          })}
        >
          {action}
        </div>
      )}

      {affix && (
        <div
          className={classNames({
            "pointer-events-none flex items-center justify-center whitespace-nowrap rounded-r bg-gray-50 text-sm text-gray-600":
              true,
            "px-3": !dense,
            "px-1": dense,
          })}
        >
          {affix}
        </div>
      )}
    </div>
  );
});

TextInput.displayName = "TextInput";

/**
 * Utility function to help parse percentage values emitted from text inputs.
 * Percentage values are internally stored between 0 and 1, but are displayed between 0 and 100.
 */
export const parsePercentageTextInput = (value: string, params?: { allowDecimals: boolean }) => {
  if (value === "") {
    return null;
  }

  const allowDecimals = params?.allowDecimals ?? true;
  const method = allowDecimals ? parseFloat : parseInt;

  return method(value) / 100;
};

/**
 * Utility function to help display percentage values inside text inputs.
 * Percentage values are internally stored between 0 and 1, but are displayed between 0 and 100.
 */
export const formatPercentageTextInput = (value: number | null, params?: { precision?: number }) => {
  if (value === null) {
    return "";
  }

  const precision = params?.precision ?? 1;

  return +(value * 100).toFixed(precision);
};
